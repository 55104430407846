import React from "react"
import {Col, Container, Row} from "react-bootstrap";
import {graphql} from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Agecolumns from "../../blocks/agecolumns";

export const data = graphql`query VocabularyQuery {
  bird: file(relativePath: {eq: "SVG/home-bird.svg"}) {
    publicURL
  }
  fiveseven: file(relativePath: {eq: "en/vocabulary/5-7_Vocab.pdf"}) {
    publicURL
  }
  sevennine: file(relativePath: {eq: "en/vocabulary/7-9_Vocab.pdf"}) {
    publicURL
  }
  nineeleven: file(relativePath: {eq: "en/vocabulary/9-11_Vocab.pdf"}) {
    publicURL
  }
}`

const VocabularyPage = ({ data }) => {
    const ageGroup = [
        {
            image: 'SVG/home-5-7.svg',
            ageGroup: '5-7',
            resourcesLink: data.fiveseven.publicURL,
            resourcesLinkText: 'View online',
            glossaryLink: data.fiveseven.publicURL,
            glossaryLinkText: 'Download',
        },
        {
            image: 'SVG/home-7-9.svg',
            ageGroup: '7-9',
            resourcesLink: data.sevennine.publicURL,
            resourcesLinkText: 'View online',
            glossaryLink: data.sevennine.publicURL,
            glossaryLinkText: 'Download',
        },
        {
            image: 'SVG/home-9-11.svg',
            ageGroup: '9-11',
            resourcesLink: data.nineeleven.publicURL,
            resourcesLinkText: 'View online',
            glossaryLink: data.nineeleven.publicURL,
            glossaryLinkText: 'Download',
        }
    ]

    return (
        <Layout lang={'cy'} langURL={'/cy/vocabulary/'} navLang={'en'}>
            <Seo title="Vocabulary resources" description="All the information you need to teach your class"
                 lang={'en-GB'}/>
            <section className={'home-banner'} role={'banner'}>
                <Container fluid={true} bsPrefix={'wrap'} className={'h-100'}>
                    <Row className={'h-100'}>
                        <div className={'home-banner--content offset-7 d-flex h-100'}>
                            <p className={'home-banner--content__text my-auto'}>
                                Learning activity ideas, support resources and helpful tools for teachers!
                            </p>
                            {/*
                        <p className={'home-banner--content__text'}>Complete tasks with your class to enter a prize
                            draw!*</p>
                        <p>
                            <Link className={'home-banner--button'} to={'/en/resources/'}>
                                Find out more
                            </Link>
                        </p>
                    */}
                        </div>
                    </Row>
                </Container>
            </section>
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-0'}>
                            <h1 className={'content--h1'}>Welcome to the vocabulary resources</h1>
                            <p className={'mb-0'}>These resources are broken down into age ranges to help you decide which to download for the
                                specific tasks you have in mind.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'content--home--column--bird'}>
                            <p className={'pb-5'}>Access downloadable PDFs to print out for your class, as well as interactive games and
                                activities to play in pairs and small groups.</p>
                            <img alt={'Little bird'} loading={'lazy'} src={data.bird.publicURL} style={{width: '60px'}}/>
                        </Col>
                        {ageGroup.map((val, idx) => (
                            <Agecolumns key={idx} image={val.image} ageGroup={val.ageGroup}
                                        resourcesLink={val.resourcesLink} resourcesLinkText={val.resourcesLinkText}
                                        glossaryLink={val.glossaryLink} glossaryLinkText={val.glossaryLinkText}/>
                        ))}
                    </Row>
                </Container>
            </main>
        </Layout>
    )

}

export default VocabularyPage