import React from 'react'
import { Col } from "react-bootstrap"

function Agecolumns({ image, ageGroup, resourcesLink, resourcesLinkText, glossaryLink, glossaryLinkText, newWindow }) {
    let resourceButton = (<a href={resourcesLink} className="content--home--button">{resourcesLinkText}</a>);
    if(resourcesLinkText === "View online") {
        resourceButton = (<a href={resourcesLink} className="content--home--button" target="_blank" rel={'noreferrer'}>{resourcesLinkText}</a>);
    }
    if(resourcesLinkText === "Gweld ar-lein") {
        resourceButton = (<a href={resourcesLink} className="content--home--button" target="_blank" rel={'noreferrer'}>{resourcesLinkText}</a>);
    }
    let glossaryButton = (<a href={glossaryLink} className="content--home--button">{glossaryLinkText}</a>);
    if(glossaryLinkText === "Download") {
        glossaryButton = (<a href={glossaryLink} className="content--home--button" download>{glossaryLinkText}</a>);
    }
    if(glossaryLinkText === "Lawrlwytho") {
        glossaryButton = (<a href={glossaryLink} className="content--home--button" download>{glossaryLinkText}</a>);
    }
    return(
        <Col xs={12} md={6} lg={4} className={'mb-4 mb-lg-0'}>
            <div className="content--home--column p-5">
                <div className="content--home--column--icon pb-5 pb-md-4 pb-lg-3 pb-xl-4">
                    <img src={`/assets/site/${image}`} alt={'Age Badge ' + ageGroup} width="100%" />
                </div>
                <div className="content--home--text py-md-4 py-lg-3 py-xl-4 d-none">
                    <p>Access downloadable PDFs to print out for the class, as well as interactive games and activities
                        to play in pairs and small groups.</p>
                </div>
                <div className="content--home--buttons py-4 py-md-3 py-lg-2 py-xl-3">
                    <p className="mt-0 mb-4 mb-md-2">
                        { resourceButton }
                    </p>
                    <p className="m-0">
                        { glossaryButton }
                    </p>
                </div>
            </div>
        </Col>
    )
}

export default Agecolumns